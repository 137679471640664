import Axios from "axios";
import axios, {AxiosResponse} from "axios";
import {emitCustomEvent} from "react-custom-events";
import FavoriteToggleResponseData = App.Data.Responses.FavoriteToggleResponseData;
import RecipeData = App.Data.RecipeData;
import ShoppingListItemData = App.Data.ShoppingListItemData;
import SearchResultResponseData = App.Data.Responses.SearchResultResponseData;
import RecipeInMealPlanToggleResponseData = App.Data.Responses.RecipeInMealPlanToggleResponseData;
import MealPlanData = App.Data.MealPlanData;
import MealPlanCreatedResponseData = App.Data.Responses.MealPlanCreatedResponseData;
import PantryMealPlansResponseData = App.Data.Responses.PantryMealPlansResponseData;
import MealPlanUpdatedResponseData = App.Data.Responses.MealPlanUpdatedResponseData;
import ResponseData = App.Data.Responses.ResponseData;
import GeneratedRecipeCreatedResponseData = App.Data.Responses.GeneratedRecipeCreatedResponseData;


/**
 * Recipes API
 */
const updateRecipe = async (uuid: string, data: any): Promise<AxiosResponse<ResponseData>> => {
  return await axios.patch(route('dashboard.recipe.update', {uuid}), {data})
}

const updateImage = async (uuid: string, data: any): Promise<AxiosResponse<ResponseData>> => {
  return await axios.post(route('dashboard.recipe.image.update', {uuid}), {data})
}

const toggleFavorite = async (recipe: RecipeData): Promise<AxiosResponse<FavoriteToggleResponseData>> => {
  return await Axios.post(
    route('pantry.favorite.toggle.recipe', {recipe: recipe.slug})
  )
}

const search = async (search: string): Promise<AxiosResponse<SearchResultResponseData>> => {
  return await Axios.post(
    route('search', {search})
  )
}

const approve = async (recipe: RecipeData) => {
  await axios.post(route('dashboard.recipe.approve', {recipe: recipe.uuid}))
}

/**
 * Shopping List API
 */
const clearList = async (shoppingListUuid: string): Promise<AxiosResponse<ResponseData>> => {
  const result = await Axios.post(
    route('pantry.shopping.clear', {shoppingList: shoppingListUuid})
  )
  emitCustomEvent('shopping-list::cleared')
  return result
}

const addToList = async (recipe: RecipeData): Promise<AxiosResponse<ResponseData>> => {
  const result = await Axios.post(
    route('pantry.shopping.add.recipe', {recipe: recipe.slug})
  )
  emitCustomEvent('shopping-list::added')
  return result

}

const addMealPlanToList = async (mealPlan: MealPlanData): Promise<AxiosResponse<FavoriteToggleResponseData>> => {
  const result = await Axios.post(
    route('pantry.shopping.add.meal-plan', {mealPlan: mealPlan.uuid})
  )
  emitCustomEvent('shopping-list::added')
  return result
}

const removeItem = async (item: ShoppingListItemData) => {
  await Axios.post(
    route('pantry.shopping.remove.item', {uuid: item.uuid})
  )
  emitCustomEvent('shopping-list::removed')
}

const importJson = async (json: string): Promise<AxiosResponse<GeneratedRecipeCreatedResponseData>> => {
  return await axios.post(route('dashboard.generated-recipe.store.json'), {json})
}

/**
 * Meal Plan
 */

const makeMealPlan = async (name: string, recipeQuickAdd?: string): Promise<AxiosResponse<MealPlanCreatedResponseData>> => {
  return await Axios.post(route('meal-plan.create'), {name, recipeQuickAdd})
}

const updateMealPlan = async (mealPlan: MealPlanData, name: string): Promise<AxiosResponse<MealPlanUpdatedResponseData>> => {
  return await Axios.post(route('meal-plan.update', {uuid: mealPlan.uuid}), {name})
}

const deleteMealPlan = async (mealPlan: MealPlanData): Promise<void> => {
  await Axios.post(route('meal-plan.delete', {uuid: mealPlan.uuid}))
}

const pantryMealPlans = async (): Promise<AxiosResponse<PantryMealPlansResponseData[]>> => {
  return await Axios.get(route('pantry.meal-plans'))
}

const toggleRecipeInMealPlan = async (mealPlan: MealPlanData, recipe: RecipeData): Promise<AxiosResponse<RecipeInMealPlanToggleResponseData>> => {
  return await axios.post(
    route('meal-plan.add.recipe', {
      mealPlan: mealPlan.uuid,
      recipe: recipe.slug
    })
  )
}

const Api = {
  recipe: {
    toggleFavorite,
    approve,
    update: updateRecipe,
    search,
    importJson,
    updateImage
  },
  shopping: {
    clearList,
    addToList,
    addMealPlanToList,
    removeItem
  },
  mealPlan: {
    make: makeMealPlan,
    update: updateMealPlan,
    delete: deleteMealPlan,
    toggleRecipe: toggleRecipeInMealPlan,
    pantryMealPlans
  }
}

export default Api
